
import React from "react"

import ProductBasicEditorPage from "../../components/negosyonow/controls/productbasiceditor"

const searchFields = [
	{"label":"Image", "dbfield": "nnproduct_image", "type": "image", "filtertype": "image"},
	{"label":"Seller SKU", "dbfield": "nnproduct_orgsku", "type": "text", "filtertype": "textbox"},
	{"label":"Full Name / Display", "dbfield": "nnproduct_fullname", "type": "text", "filtertype": "textbox"},
	{"label":"Weight(kg)", "dbfield": "nnproduct_pkgweight", "type": "numeric", "filtertype": "numeric"},
	{"label":"Length(cm)", "dbfield": "nnproduct_pkglength", "type": "numeric", "filtertype": "numeric"},
	{"label":"Width(cm)", "dbfield": "nnproduct_pkgwidth", "type": "numeric", "filtertype": "numeric"},
	{"label":"Height(cm)", "dbfield": "nnproduct_pkgheight", "type": "numeric", "filtertype": "numeric"},
	{"label":"Volume(L)", "dbfield": "nnproduct_pkgvolume", "type": "numeric", "filtertype": "numeric"},
	{"label":"Last Update", "dbfield": "nnproduct_lastupdate", "type": "datetime", "filtertype": "datetime"},
];

const formFields = [
	[
		{"label":"Primary Image", "field": "nnproduct_image", "value": "", "input": "image", "mode": "readonly"},
		{"label":"Seller SKU", "field": "nnproduct_orgsku", "value": "", "input": "textbox", "mode": "readonly"},
		{"label":"Full Name / Display", "field": "nnproduct_fullname", "value": "", "input": "textbox", "mode": "readonly"},
		{"label":"Has Page", "field": "nnproduct_haspage", "value": "", "input": "hidden", "mode": "readonly"},
		{"label":"Webpage", "field": "nnproduct_actualurl", "value": "", "input": "url", "mode": "readonly", "urlprefix":"https://negosyonow.com/"},
		{"label":"Last Updated", "field": "nnproduct_lastupdate", "value": "", "input": "updatetime", "mode": "readonly"},
		{"label":"Active", "field": "nnproduct_active", "value": "", "input": "checkbox", "mode": "readonly"},
		{"label":"Weight (Kilograms)", "field": "nnproduct_pkgweight", "value": "", "input": "numeric", "mode": "required"},
		{"label":"Length (cm)", "field": "nnproduct_pkglength", "value": "", "input": "numeric", "mode": "required"},
		{"label":"Width (cm)", "field": "nnproduct_pkgwidth", "value": "", "input": "numeric", "mode": "required"},
		{"label":"Height (cm)", "field": "nnproduct_pkgheight", "value": "", "input": "numeric", "mode": "required"},
		{"label":"Volume (Liters)", "field": "nnproduct_pkgvolume", "value": "", "input": "numeric", "mode": "required"},
		{"label":"Irregular/Not Boxed", "field": "nnproduct_pkgirregular", "value": "", "input": "checkbox", "mode": "required"},
		{"label":"Storage Instructions", "field": "nnproductstorage_id", "value": "", "input": "combo", "mode": "required", "options": [
			{"value":1,"display":"N/A"},
			{"value":2,"display":"Cool Dry Place"},
			{"value":3,"display":"Refrigerated"},
			{"value":4,"display":"Frozen"},
		]},
		{"label":"Shelf Life", "field": "nnproduct_shelflife", "value": "", "input": "textbox", "mode": "normal"},
	]
];

const ProductdimensionsPage = ({location}) => {
	return <ProductBasicEditorPage
				location={location}
				formFields={formFields}
				searchFields={searchFields}
			/>
}


export default ProductdimensionsPage;
